<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol>
                <CInput
                  label="Kayıt Tarihi"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol sm ="6">
                <CInput
                  label="Arayan Numara"
                  placeholder="05*********"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.callerIdNumber"
                />
              </CCol>
              <CCol sm ="6">
                <CInput
                  label="Aranan Numara"
                  placeholder="05********"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.destinationNumber"
                />
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "CallEventFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter(data)
            }
       }
   }
</script>